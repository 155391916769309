import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'
import {
  Tooltip,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  Done as DoneIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import _ from 'lodash'
import './_Profile.scss';
import { Container, Row, Col, Button, Form, FormGroup } from 'react-bootstrap';
import ImageCropper from 'components/common/image-cropper'
import Dropdown from 'components/Initial/Items/Dropdown'
import PlacesSearch from 'components/Initial/Items/PlacesSearch'
import userSample from '../../../assets/images/user-upload.png';

import {
  linkPayment as linkPaymentService,
  updateProfile as updateProfileService,
} from 'services/user'
import { upload } from 'services/file'
import { uploadStoreImage } from 'services/stores'
import { setStore as setStoreAction } from 'reducers/store/actions'
import { setUser as setUserAction } from 'reducers/user/actions'
import { openToast } from 'reducers/toast/actions'

const Profile = ({ user, dispatch }) => {
  console.log('onProfile')
  const [croppedImage, setCroppedImage] = useState({
    blob: '',
    base64: '',
  })
  const [submitError, setSubmitError] = useState('')

  const [geo, setGeo] = useState()

  const profileImgRef = useRef(null)

  const { register, watch, setValue, control } = useForm()

  // address places search
  const [searchedAddress, setSearchedAddress] = useState(watch('address'))
  const onAddressSelect = address => {
    setValue('address', address.formatted_address)
    setGeo(address.geometry)
  }
  const updateKeyword = keyword => {
    setSearchedAddress(keyword)
  }
  const setSearchedAddressCb = useCallback(_.debounce(updateKeyword, 1500), [])
  const onFullAddressChange = () => {
    setSearchedAddressCb(watch('address'))
  }

  const linkPayment = async () => {
    let res
    try {
      res = (await linkPaymentService()).data
    } catch (err) {
      return console.error(err)
    }
    const redirectUrl = res.data
    window.location.assign(redirectUrl)
  }

  const onCropped = async ({ blob, base64 }) => {
    if (!base64) return setSubmitError('Not uploaded yet')
    // temporarily render img
    setCroppedImage({
      blob, base64
    })
    // upload
    let uploadImgRes
    try {
      const res = await upload(base64)
      uploadImgRes = res.data
    } catch (err) {
      return setSubmitError(err.response.data.err)
    }
    if (uploadImgRes.result !== 'success') {
      return setSubmitError(uploadImgRes.err)
    }
    let savedStore
    try {
      savedStore = (await uploadStoreImage('Fake', uploadImgRes.file._id)).data.data
    } catch (err) {
      return setSubmitError(err.response.data.err)
    }
    dispatch(setStoreAction(savedStore))
    setCroppedImage({
      blob: '',
      base64: ''
    })
  }

  const updateProfile = async () => {
    setSubmitError()
    let res
    try {
      res = (await updateProfileService(user._id, {
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        phoneNumber: watch('phoneNumber'),
      })).data
    } catch (err) {
      return console.error(err)
    }
    if (res.result !== 'success') {
      return setSubmitError(res.err)
    }
    dispatch(openToast({
      message: 'Profile updated!',
      type: 'success',
    }))
    const updatedUser = res.data
    dispatch(setUserAction(updatedUser))
    dispatch(setStoreAction(updatedUser.store))
  }

  const onPlacesChange = (places) => {
    // in case user not select place, we use the first one
    const defaultPlace = places[0]
    setGeo(defaultPlace.geometry)
  }

  return (
    <div className="main-page-wrapper px-lg-40 pb-5">
      <Container fluid>
        <div className="page-title d-flex py-3">My Profile</div>
        <div className="my-profile-container">
          <div className="my-profile-inner">
            <Row>
              <Col xs="12" xl="4" className="pr-lg-2">
                <div className="user-upload-wrapper mb-5 mb-xl-0">
                  <div className="d-flex justify-content-center mb-4">
                    {/* <img
                      src={croppedImage.blob || store.image || userSample}
                      alt="User"
                      className="img-fluid"
                    /> */}
                    <ImageCropper
                      onCroppedImg={onCropped}
                      text='Upload'
                      setImg={croppedImage.blob || userSample}
                      refLabel={profileImgRef}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-stretch">
                    <Button className="btn-save" onClick={() => profileImgRef.current.click()}>Upload</Button>
                    {/* <ImageCropper
                      onCroppedImg={onCropped}
                      text='Upload'
                      setImg={croppedImage.blob}
                      ref={profileImgRef}
                    /> */}
                  </div>
                </div>
              </Col>
              <Col xs="12" xl={{ size: 7, offset: 1 }}>
                <div className="myprofile-frm-right px-3 px-xl-0">
                  <Form>
                    <Form.Group>
                      <Row>
                        <Col xs="6">
                          <Form.Control
                            placeholder="First name"
                            defaultValue={user.firstName}
                            ref={register}
                            name='firstName'
                            value={watch('firstName')}
                          />
                        </Col>
                        <Col xs="6">
                          <Form.Control
                            placeholder="Last name"
                            defaultValue={user.lastName}
                            ref={register}
                            name='lastName'
                            value={watch('lastName')}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        defaultValue={user.email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        placeholder="Phone Number"
                        defaultValue={user.phoneNumber}
                        ref={register}
                        name='phoneNumber'
                        value={watch('phoneNumber')}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
            <div className='action-groups'>
              {!user.stripeAccount && (
                <Button className="btn-save" onClick={linkPayment}>Link Bank Account</Button>
              )}
              <Button className="btn-save" onClick={updateProfile}>Save Changes</Button>
            </div>
            {submitError && (
              <p style={{ color: 'red', textAlign: 'center' }}>{submitError}</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default connect(mapStateToProps)(Profile)
