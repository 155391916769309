import axios from 'utils/axios'
import { store } from 'store'
import { CLEAR_USER } from 'reducers/user/constants'

export default (option) => {
  return axios(option).catch(err => {
    if (err.response.status === 401) {
      store.dispatch({
        type: CLEAR_USER,
      })
    }
    throw err
  })
}