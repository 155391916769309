import { intl } from './intl'
import user from './user'
import category from './category'
import store from './store'
import toast from './toast'
import modals from './modals'
import common from './common'
import buildings from './buildings'

export default {
  intl,
  user,
  category,
  store,
  toast,
  modals,
  common,
  buildings,
}