import React, { useState, useCallback, memo } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { useHistory } from 'react-router'

import { validateEmail, validatePassword, validatePhone, validateZipcode } from 'utils/input_validator'
import Input from '../Items/Input';
import ProgressButton from 'components/common/button/ProgressButton';
import Checkbox from '../Items/Checkbox';
import Dropdown from '../Items/Dropdown';
import Modal from 'components/common/modal'
import PlacesSearch from '../Items/PlacesSearch'
import TermsAndConditions from './TermsAndConditions'
import backgroundImg from '../../../assets/images/bg_registration.jpg';
import './_Signup.scss';

import { signup } from 'services/user'
import { toggleWarning } from 'reducers/modals/actions'

const storeTypes = [
  {
    name: 'Supermarket',
    value: 'supermarket',
  },
  {
    name: 'Convenience Store',
    value: 'convenience_store',
  },
  {
    name: 'Liquor Store',
    value: 'liquor_store',
  },
  {
    name: 'Other',
    value: 'other',
  }
]

const Signup = memo(({ openWarningModal }) => {
  const history = useHistory()
  const defaultFormValue = {
    storeType: storeTypes[0].value,
  }
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const { register, handleSubmit, errors, watch, clearError, setValue, control } = useForm({ defaultValues: defaultFormValue });
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');

  const [submitError, setSubmitError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const [geo, setGeo] = useState()

  // address places search
  const [searchedAddress, setSearchedAddress] = useState(watch('fullAddress'))
  const onAddressSelect = address => {
    setValue('fullAddress', address.formatted_address)
    setGeo(address.geometry)
  }
  const updateKeyword = keyword => {
    setSearchedAddress(keyword)
  }
  const setSearchedAddressCb = useCallback(_.debounce(updateKeyword, 1500), [])
  const onFullAdressChange = () => {
    setSearchedAddressCb(watch('fullAddress'))
  }

  const setTermsAndConditionsChecked = checked => {
    setChecked(checked)
    checked && setOpenModal(true)
  }

  const onSubmit = async (payload) => {
    setSubmitting(true)
    let data
    try {
      const res = await signup({
        firstName: payload.firstName,
        lastName: payload.lastName,
        // username: payload.username,
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        address: payload.fullAddress,
        geo,
        storeName: payload.storeName,
        storeType: payload.storeType,
        password: payload.password,
        zipCode: payload.zipCode,
      })
      data = res.data
    } catch (err) {
      setSubmitting(false)
      return setSubmitError(err.response.data.err)
    }
    setSubmitting(false)
    if (data.result !== 'success') {
      return setSubmitError(data.err)
    }
    openWarningModal({
      callback: () => history.push('/login'),
      message: 'A verification link has been sent to your email account. Please verify your email to continue using.'
    })
  };
  let errorPasswordMessage = '';
  let errorConfirmPasswordMessage = '';
  let errorEmailMessage = '';
  let errorPhoneMessage = '';
  let errorZipcode = ''
  if (errors) {
    if (errors.password) {
      errorPasswordMessage = errors.password.message;
    }
    if (errors.confirmPassword) {
      errorConfirmPasswordMessage = errors.confirmPassword.message;
    }
    if (errors.email) {
      errorEmailMessage = errors.email.message;
    }
    if (errors.phoneNumber) {
      errorPhoneMessage = errors.phoneNumber.message;
    }
    if (errors.zipCode) {
      errorZipcode = errors.zipCode.message
    }
  }

  const onPlacesChange = (places) => {
    // in case user not select place, we use the first one
    const defaultPlace = places[0]
    setGeo(defaultPlace.geometry)
  }

  return (
    <div className="Registration">
      <div className="Registration-container">
        <div className="Registration-blockLeft">
          <img className="Registration-blockLeftImg" src={backgroundImg} alt="registration img" />
        </div>
        <div className="Registration-blockRight">
          <form onSubmit={handleSubmit(onSubmit)} className="Registration-form" autocomplete="false">
            <div className="Registration-title">
              <span className="Registration-titleCart">CART</span>&ORDER
                        </div>
            <div className="Registration-subTitle">
              <span className="Registration-subTitleCart">Business</span>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxLeft">
                <Input
                  label="First name"
                  type="text"
                  error={errors.firstName}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('firstName')}
                  name="firstName"
                />
              </div>
              <div className="Registration-boxRight">
                <Input
                  label="Last name"
                  type="text"
                  error={errors.lastName}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('lastName')}
                  name="lastName"
                />
              </div>
            </div>
            {/* <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Username"
                  type="text"
                  error={errors.username}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('username')}
                  name="username"
                />
              </div>
            </div> */}
            <div className="Registration-box">
              <div className="Registration-boxLeft">
                <Input
                  errorMessage={errorEmailMessage}
                  label="Email"
                  type="Email"
                  error={errors.email}
                  refInput={register({
                    required: true,
                    validate: (value) => validateEmail(String(value))
                  })}
                  value={watch('email')}
                  name="email"
                />
              </div>
              <div className="Registration-boxRight">
                <Input
                  errorMessage={errorPhoneMessage}
                  label="Phone Number"
                  type="tel"
                  error={errors.phoneNumber}
                  refInput={register({
                    required: true,
                    validate: (value) => validatePhone(String(value))
                  })}
                  value={watch('phoneNumber')}
                  name="phoneNumber"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Store Name"
                  type="text"
                  error={errors.storeName}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('storeName')}
                  name="storeName"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxLeft">
                <Dropdown
                  label="Store Type"
                  name='storeType'
                  value={watch('storeType')}
                  items={storeTypes}
                  control={control}
                />
              </div>
              <div className="Registration-boxRight">
                <Input
                  errorMessage={errorZipcode}
                  label="Zip Code"
                  type="string"
                  error={errors.zipCode}
                  refInput={register({
                    required: true,
                    validate: (value) => validateZipcode(String(value))
                  })}
                  value={watch('zipCode')}
                  name="zipCode"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Full Address"
                  error={errors.fullAddress}
                  type="text"
                  refInput={register({
                    required: true,
                  })}
                  value={watch('fullAddress')}
                  name="fullAddress"
                  onChange={onFullAdressChange}
                />
                <PlacesSearch
                  keyword={searchedAddress}
                  onSelect={onAddressSelect}
                  onListChange={onPlacesChange}
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxLeft">
                <Input
                  errorMessage={errorPasswordMessage}
                  label="Password"
                  type="password"
                  error={errors.password}
                  refInput={register({
                    required: true,
                    validate: (value) => validatePassword(String(value), true, watchConfirmPassword, clearError)
                  })}
                  value={watch('password')}
                  name="password"
                />
              </div>
              <div className="Registration-boxRight">
                <Input
                  errorMessage={errorConfirmPasswordMessage}
                  label="Confirm Password"
                  type="password"
                  refInput={register({
                    required: true,
                    validate: (value) => validatePassword(String(value), false, watchPassword, clearError)
                  })}
                  error={errors.confirmPassword}
                  value={watch('confirmPassword')}
                  name="confirmPassword"
                />
              </div>
            </div>
            <div className="Registration-blockCheckbox">
              <div>
                <Checkbox
                  label="I agree with terms and conditions"
                  name="registrationCheckbox"
                  checked={checked}
                  onClick={() => setTermsAndConditionsChecked(!checked)}
                />
              </div>
            </div>
            {submitError && (
              <p style={{ color: 'red', textAlign: 'center' }}>{submitError}</p>
            )}
            <div className="Registration-blockButton">
              <ProgressButton
                type="submit"
                onClick={() => {
                }}
                disabled={false}
                submitting={submitting}
                classes="success"
              >
                Register
              </ProgressButton>
            </div>
            <div className="Registration-blocLink">
              <a className="Registration-link" href="/login">
                Already have an account? Sign in.
                            </a>
            </div>
            <div className="Registration-blocPolicy">Term of use. Privacy policy</div>
          </form>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className='tac-modal'
      >
        <TermsAndConditions />
      </Modal>
    </div>
  );
})

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})
export default connect(null, mapDispatchToProps)(Signup);
