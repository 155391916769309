import React from 'react'
import { connect } from 'react-redux'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { closeToast } from 'reducers/toast/actions'

const Toast = ({
  toast,
  dispatch,
}) => {
  const handleClose = () => {
    dispatch(closeToast())
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={toast.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={toast.type || 'error'}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  )
}

const mapStateToProps = ({ toast }) => ({
  toast,
})

export default connect(mapStateToProps)(Toast)
