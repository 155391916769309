export const formatMoney = n => Math.floor(n * 100) / 100

export const renderMoney = n => n ? Number(n).toFixed(2) : undefined

export const orderStatuses = {
  PROCESSING: 'Processing',
  PACKING: 'Packing',
  PACKED: 'Packed',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
}
export const orderStatusMapping = {
  NEW: orderStatuses.PROCESSING,
  PACKING: orderStatuses.PACKING,
  PACKED: orderStatuses.PACKED,
  DELIVERED: orderStatuses.DELIVERED,
  CANCELLED: orderStatuses.CANCELLED,
}
export const getMappedOrderStatus = status => {
  return orderStatusMapping[status] || orderStatuses.PROCESSING
}

export const itemStatusesText = {
  PROCESSING: 'Processing',
  PACKED: 'Packed',
  OUT_OF_STOCK: 'Out of stock',
  REMOVED: 'Removed',
}

export const itemStatuses = Object.keys(itemStatusesText).reduce((statuses, status) => {
  statuses[status] = status
  return statuses
}, {})
export const getMappedItemStatus = status => {
  return itemStatusesText[status] || itemStatusesText.PROCESSING
}

export const ORDER_ACTIONS = [
  {
    name: 'Packing',
    value: 'PACKING',
  },
  {
    name: 'Packed',
    value: 'PACKED',
  },
  {
    name: 'Delivered',
    value: 'DELIVERED',
  },
]