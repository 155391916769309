import request from './base'

export const uploadStoreImage = (storeId, fileId) => {
  return request({
    url: `/stores/${storeId}/upload_store_image`,
    method: 'POST',
    data: {
      image: fileId
    }
  })
}

export const getReports = (storeId, type) => {
  return request({
    url: `/stores/${storeId}/reports`,
    method: 'POST',
    data: {
      type
    }
  })
}