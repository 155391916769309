/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  statusBadge: {
    display: 'inline-block',
    color: '#ffffff',
    padding: '3px 15px',
    borderRadius: '10px',
    background: '#ddd',
    width: '7.5rem',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  statusSuccess: {
    backgroundColor: '#5fa01b',
  },
  statusNew: {
    backgroundColor: '#feb42c',
  },
  statusFailed: {
    backgroundColor: '#ff0039',
  },
  statusPending: {
    backgroundColor: '#2196f3'
  },
}));

const NEW_STATUSES = [
  'NEW',
  'PENDING',
]

const PENDING_STATUSES = [
  'PREPARING'
]

const SUCCESS_STATUSES = [
  'PACKING',
  'PACKED',
  'PAID',
  'COMPLETED',
  'DELIVERED'
]
const FAILED_STATUSES = [
  'CANCELLED'
]

const StatusBadge = ({ content }) => {
  const classes = useStyles();
  const className = cx(classes.statusBadge, {
    [classes.statusNew]: NEW_STATUSES.includes(content),
    [classes.statusPending]: PENDING_STATUSES.includes(content),
    [classes.statusSuccess]: SUCCESS_STATUSES.includes(content),
    [classes.statusFailed]: FAILED_STATUSES.includes(content)
  });
  return (
    <span
      className={className}
      color="secondary"
    >
      {(SUCCESS_STATUSES.includes(content)) && <svg xmlns="http://www.w3.org/2000/svg" width="9.338" height="8.249" viewBox="0 0 9.338 8.249" style={{ marginRight: '4px' }}>
        <g id="check-mark_3_" data-name="check-mark (3)" transform="translate(-0.001 -20.566)">
          <path id="Path_359" data-name="Path 359" d="M8.931,20.629c-.421-.227-.891.211-1.167.47C7.132,21.715,6.6,22.428,6,23.077c-.664.713-1.28,1.426-1.961,2.123a5.847,5.847,0,0,0-1.07,1.3,21.273,21.273,0,0,0-1.734-1.685c-.47-.357-1.248-.616-1.232.243a5.494,5.494,0,0,0,1.75,3.079,1.851,1.851,0,0,0,1.183.681c.567.032,1.151-.648,1.491-1.021.6-.648,1.086-1.378,1.637-2.042.713-.875,1.442-1.734,2.139-2.626C8.639,22.574,10.017,21.213,8.931,20.629Z" fill="#fff" />
        </g>
      </svg>}
      {content}
    </span>
  );
};

export default StatusBadge;
