import { SET_STORE } from './constants'

export const initState = {
  store: {
    _id: undefined,
    name: undefined,
    type: undefined,
    image: undefined,
    address: undefined,
    zipCode: undefined,
    tax: undefined,
    shippingFee: undefined,
    pickupFee: undefined,
    openTime: {
      mon: {
        from: '',
        to: '',
      },
      tue: {
        from: '',
        to: '',
      },
      wed: {
        from: '',
        to: '',
      },
      thu: {
        from: '',
        to: '',
      },
      fri: {
        from: '',
        to: '',
      },
      sat: {
        from: '',
        to: '',
      },
      sun: {
        from: '',
        to: '',
      },
    },
    minPackingTime: undefined,
  }
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_STORE:
      return { ...state, store: {
        ...payload,
        openTime: payload.openTime || state.store.openTime
      } }

    default:
      return state
  }
}