import { withStyles, makeStyles, styled } from '@material-ui/core/styles'

import {
  TableCell,
  TableRow,
} from '@material-ui/core';

export const StyledTableThCell = styled(TableCell)({
  fontFamily: '"Source Sans Pro", sans-serif',
  fontWeight: 700,
  fontSize: '13px',
  color: '#7B8A96',
})

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edf1f4',
    color: '#7b8a96',
  },
  body: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 400,
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fa',
    },
    color: '#4d4f5c',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dedede',
    }
  },
}))(TableRow);

export const useStyles = makeStyles({
  table: {
    minWidth: 900,
  },
  tableContainer: {
    padding: 15,
  },
});