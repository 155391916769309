import React, { useEffect, useState, memo } from 'react'

import './styles.scss'

const PlacesSearch = memo(({ keyword, onSelect, inputRef, onListChange }) => {
  const [places, setPlaces] = useState([])
  const [ggService, setGgService] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById('map'))
    const service = new window.google.maps.places.PlacesService(map)
    setGgService(service)
  }, [])
  useEffect(() => {
    if (!keyword || loading) return
    setLoading(true)
    ggService.textSearch({
      query: keyword,
      fields: ['formatted_address', 'geometry']
    }, res => {
      const places = res.map(place => ({
        formatted_address: place.formatted_address,
        geometry: [place.geometry.location.lng(), place.geometry.location.lat()]
      }))
      setPlaces(places)
      onListChange && onListChange(places)
      setLoading(false)
    });
    // search(keyword).then(res => {
    //   console.log(res)
    // })
  }, [keyword])
  const handleSelect = i => {
    onSelect && onSelect(places[i])
    if (inputRef) inputRef.current.value = places[i].formatted_address
    setPlaces([])
  }
  return (
    <div className={`places-search ${places.length > 0 && 'show'}`}>
      <div id="map"></div>
      <div className='gg-places'>
        {places.map((place, i) => (
          <div
            key={i}
            className='gg-place'
            onClick={() => handleSelect(i)}
          >
            {place.formatted_address}
          </div>
        ))}
      </div>
    </div>
  )
})

export default PlacesSearch