import {
  TOGGLE_WARNING,
} from './constants'

export const initState = {
  warning: {
    open: false,
    withCancel: false,
    callback: () => { },
    message: ''
  },
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case TOGGLE_WARNING:
      return { ...state, warning: payload }

    default:
      return state
  }
}