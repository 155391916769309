import React from 'react'
import { connect } from 'react-redux'
import { Modal } from '@material-ui/core'

import Button from 'components/common/button'

import './styles.scss'

import CloseIcon from './icons/close.svg'

import { toggleWarning } from 'reducers/modals/actions'

const WarningModal = ({ modalState, toggleWarning, styles }) => {
  const onOK = () => {
    modalState.callback && modalState.callback()
    toggleWarning({ open: false })
  }
  return (
    <Modal
      open={modalState.open}
      onClose={() => toggleWarning({ open: false })}
      className="modal-warning-layout"
    >
      <div className='modal-container' style={styles}>
        <span onClick={() => toggleWarning({ open: false })} className='modal-close'>
          <img src={CloseIcon} alt='close modal' />
        </span>
        {modalState.message}
        <div className='modal-actions'>
          {modalState.withCancel && (
            <Button onClick={() => toggleWarning({ open: false })}>Cancel</Button>
          )}
          <Button onClick={onOK} classes='success'>OK</Button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ modals }) => ({
  modalState: modals.warning
})
const mapDispatchToProps = {
  toggleWarning
}
export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
