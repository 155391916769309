import React, { useState } from 'react'

import TermsAndConditions from './components/TermsAndConditions'
import Policies from './components/Policies'

import Modal from 'components/common/modal'
import './_styles.scss'

const Footer = () => {
  const [openTADModal, setOpenTADModal] = useState(false)
  const [openPoliciesModal, setOpenPoliciesModal] = useState(false)
  return (
    <div className='footer'>
      <div className='footer-content'>
        <div className='footer-content-column'>Contact:&nbsp;<a href="mailto:cartandorder@gmail.com" target="_blank">cartandorder@gmail.com</a></div>
        <div className='footer-content-column'>
          <div className='footer-content-column-row'>
            <a onClick={setOpenTADModal} href="javascript:;">Terms and Conditions</a>
          </div>
          <div className='footer-content-column-row'>
            <a onClick={setOpenPoliciesModal} href="javascript:;">Privacy Policy</a>
          </div>
        </div>
      </div>
      <div className='footer-copyright'>
        <span>
          © 2021. ALL RIGHTS RESERVED
        </span>
      </div>
      <Modal
        open={openTADModal}
        onClose={() => setOpenTADModal(false)}
        className='tac-modal'
      >
        <TermsAndConditions />
      </Modal>
      <Modal
        open={openPoliciesModal}
        onClose={() => setOpenPoliciesModal(false)}
        className='tac-modal'
      >
        <Policies />
      </Modal>
    </div>
  )
}

export default Footer