import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { format } from 'date-fns'

import {
  Box,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'

import Dropdown from 'components/Initial/Items/Dropdown'
import Button from 'components/common/button'
import Input from 'components/Initial/Items/Input'

import './_styles.scss'

const OpenOrderFilter = ({
  onFilter,
  buildings,
  user,
}) => {
  const [buildingOptions, setBuildingOptions] = useState([])
  const [deliveryTimeOptions, setDeliveryTimeOptions] = useState([])
  const { register, watch, control, setValue } = useForm({})

  useEffect(() => {
    if (buildings.length > 0) {
      const convertedBuildings = buildings
        .filter(b => b._id === user.building)
        .map(b => ({ value: b._id, name: b.name, deliveryDays: b.deliveryDays }))
      setBuildingOptions(convertedBuildings)
      const selectedBuilding = convertedBuildings[0]
      onSetBuilding(selectedBuilding)
    }
  }, [buildings])
  const onSearch = () => {
    const deliveryTime = watch('shortId') ? undefined : watch('deliveryTime')
    const filter = {
      building: watch('building'),
      // deliveryTime,
      shortId: watch('shortId') || undefined,
    }
    onFilter && onFilter(filter, 0)
  }
  const onSetBuilding = building => {
    if (!building) return
    setValue('building', building.value)
    const deliveryTimeOptions = building?.deliveryDays.map(d => {
      return {
        name: d.format,
        value: d.value,
      }
    })
    setDeliveryTimeOptions(deliveryTimeOptions)
    setValue('deliveryTime', deliveryTimeOptions[0]?.value)
  }
  const handleBuildingChange = e => {
    const building = buildingOptions.find(b => b.value === e.target.value)
    if (building) onSetBuilding(building)
  }
  return (
    <Box component="div" mb={2} className="inventory-filter">
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        height="100%"
        py={2.5}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="font-26 text-dark-blue"
        >
          Open Order
        </Box>
      </Box>
      <div className='i-f-container'>
        <div className='i-f-form'>
          <div className='i-f-form-row mb-3'>
            <Dropdown
              label='Building'
              name='building'
              value={user.building}
              control={control}
              items={buildingOptions}
              handleChange={handleBuildingChange}
            />
            {/* <Dropdown
              label='Delivery Day'
              name='deliveryTime'
              value={watch('deliveryTime')}
              control={control}
              items={deliveryTimeOptions}
            /> */}
            <Input
              placeholder='Order ID'
              name='shortId'
              refInput={register({
                required: true,
              })}
              value={watch('shortId')}
            />
          </div>
          <div className='i-f-form-row-submit'>
            <Button
              classes='submit-btn success'
              onClick={onSearch}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </Box>
  )
}

const mapStateToProps = ({ buildings, user }) => ({
  buildings: buildings.buildings,
  user,
})
export default connect(mapStateToProps)(OpenOrderFilter)
