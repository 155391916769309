import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { useHistory } from 'react-router'

import { CircularProgress } from '@material-ui/core'

import Input from '../Items/Input';
import Button from 'components/common/button'
import Checkbox from '../Items/Checkbox';
import backgroundImg from '../../../assets/images/bg_registration.jpg';

// network
import { login } from 'services/user'
import { loadRelatedData } from 'utils/state_init'

// redux
import { setAfterLogin } from '../../../reducers/user/actions'

const Login = ({ setUser, dispatch }) => {
  const history = useHistory()

  const [remember, setRemember] = useState(false)
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const { handleSubmit, register, watch, errors } = useForm()

  const onSubmit = async (payload) => {
    setSubmitting(true)
    let data
    try {
      const res = await login(
        payload.email,
        payload.password,
        payload.remember,
      )
      data = res.data
    } catch (err) {
      setSubmitting(false)
      return setError(err.response.data.err)
    }
    setSubmitting(false)
    // res should contain email
    if (data.result !== 'success') {
      return setError(data.err)
    }
    await setUser(data.data)
    // await loadRelatedData(dispatch)
    history.push('/')
  };

  let errorEmail = ''
  let errorPasswordMessage = ''
  if (errors) {
    if (errors.errorEmail) {
      errorEmail = errors.errorEmail.message;
    }
    if (errors.password) {
      errorPasswordMessage = errors.password.message;
    }
  }

  return (
    <div className="Registration">
      <div className="Registration-container">
        <div className="Registration-blockLeft">
          <img className="Registration-blockLeftImg" src={backgroundImg} alt="registration img" />
        </div>
        <div className="Registration-blockRight">
          <form onSubmit={handleSubmit(onSubmit)} className="Registration-form">
            <div className="Registration-title">
              <span className="Registration-titleCart">CART</span>&ORDER
                        </div>
            <div className="Registration-subTitle">
              <span className="Registration-subTitleCart">Business</span>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Email"
                  type="text"
                  error={errors.email}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('email')}
                  name="email"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  errorMessage={errorPasswordMessage}
                  label="Password"
                  type="password"
                  error={errors.password}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('password')}
                  name="password"
                />
              </div>
            </div>
            {error && (
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}
            <div className="Registration-blockCheckbox">
              <div>
                <Checkbox
                  label="Remember me"
                  checked={remember}
                  onClick={() => setRemember(!remember)}
                />
              </div>
            </div>
            <div className="Registration-blockButton">
              <Button
                disabled={submitting}
                classes='success submit-button'
                type="submit"
                onClick={() => {
                }}
              >
                {submitting ? (
                  <CircularProgress color='default' size='20px' />
                ) : 'Login'}
              </Button>
            </div>
            <div className="Registration-blocLink">
              <a className="Registration-link" href="/signup">
                Don’t have an account? Sign up.
              </a>
            </div>
            <div className="Registration-blocLink">
              <a className="Registration-link" href="/forgot-password">
                Forgot password?
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setAfterLogin(user)),
  dispatch,
})
export default connect(null, mapDispatchToProps)(Login);
