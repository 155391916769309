import { TOGGLE_DRAWER } from './constants'

export const initState = {
  toggleDrawer: false
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        // toggleDrawer: payload !== undefined ? !state.toggleDrawer : payload
        toggleDrawer: payload !== undefined ? payload : !state.toggleDrawer
      }

    default:
      return state
  }
}