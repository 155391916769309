import React, { useState } from 'react'
import { connect } from 'react-redux'
import Input from '../../../../common/inputs/style1'
// import ProgressButton from 'components/Initial/Items/Button/ProgressButton'
import ProgressButton from 'components/common/button/ProgressButton'
import { useForm } from 'react-hook-form'

import { validateEmail, validatePhone, validateZipcode } from 'utils/input_validator'

import { signup } from 'services/user'
import { toggleWarning } from 'reducers/modals/actions'

const HomeSignupRightPanel = ({ openWarningModal }) => {
  const { register, handleSubmit, errors, reset } = useForm({});

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (payload) => {
    setSubmitting(true)
    let data
    try {
      const res = await signup(payload)
      data = res.data
    } catch (err) {
      setSubmitting(false)
      return
    }
    setSubmitting(false)
    if (data.result !== 'success') {
      return
    }
    openWarningModal({
      callback: () => { },
      message: 'Password is sent to your email.'
    })
    reset()
  }

  return (
    <div className='form-panel'>
      <h2>Get Started</h2>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <div className='form-row'>
          <Input
            type="text"
            placeholder='First Name'
            inputRef={register({
              required: true,
            })}
            name="firstName"
            className={errors.firstName && 'error'}
          />
          <Input
            type="text"
            placeholder='Last Name'
            inputRef={register({
              required: true,
            })}
            name="lastName"
            className={errors.lastName && 'error'}
          />
        </div>
        <div className='form-row'>
          <Input
            placeholder='Email'
            inputRef={register({
              required: true,
              validate: (value) => validateEmail(String(value))
            })}
            name="email"
            className={errors.email && 'error'}
          />
          <Input
            placeholder='Phone Number'
            inputRef={register({
              required: true,
              validate: (value) => validatePhone(String(value))
            })}
            name="phoneNumber"
            className={errors.phoneNumber && 'error'}
          />
        </div>
        <div className='form-row'>
          <Input
            placeholder='Store Zip Code'
            inputRef={register({
              required: true,
              validate: (value) => validateZipcode(String(value))
            })}
            name="zipCode"
            className={errors.zipCode && 'error'}
          />
        </div>
        <div className='form-row'>
          <Input
            placeholder='Store Name'
            inputRef={register({
              required: true,
            })}
            name="storeName"
            className={errors.storeName && 'error'}
          />
        </div>
        {/* <div className='form-row'>
        <Checkbox label='I agree with terms and conditions' />
      </div> */}
        <div className='form-row submit'>
          <ProgressButton
            classes='success'
            type="submit"
            submitting={submitting}
          >
            Submit
          </ProgressButton>
        </div>
      </form>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(HomeSignupRightPanel)