import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
  Drawer,
  Hidden,
} from '@material-ui/core'

import { toggleDrawer as toggleDrawerState } from 'reducers/common/actions'

const Sidenav = ({ showSidebar, toggleDrawer, location, history }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const _sidebarOptionStyle = (option) => {
    if (option === location.pathname) {
      return "list-group-item active"
    } else {
      return "list-group-item"
    }
  }
  const toPage = (e, page) => {
    e.preventDefault()
    history.push(page)
    toggleDrawer(false)
  }
  const sidebar = (
    <div className="SideNav">
      <div className="navbar text-capitalize bg-primary navbar-dark">
        <div className="navbar-brand ">
          <span className="font-weight-bold">CART</span>&amp;ORDER
          </div>
      </div>
      <div id="sidebar-wrapper">
        <div className="list-group list-group-flush">
          {/* <a onClick={e => toPage(e, '/dashboard')} className={_sidebarOptionStyle("/dashboard")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
              {' '}
              <path
                id="icon_dashboard"
                d="M13,19c-.6,0-1-.475-1-1.187V7.125c0-.713.4-1.187,1-1.187h2c.6,0,1,.475,1,1.187V17.813C16,18.525,15.6,19,15,19ZM7,19c-.6,0-1-.475-1-1.187V1.187C6,.476,6.4,0,7,0H9c.6,0,1,.476,1,1.187V17.813C10,18.525,9.6,19,9,19ZM1,19c-.6,0-1-.475-1-1.187V13.063c0-.713.4-1.188,1-1.188H3c.6,0,1,.476,1,1.188v4.749C4,18.525,3.6,19,3,19Z"
              />{' '}
            </svg>
            Dashboard
          </a>
          <a onClick={e => toPage(e, '/inventory')} className={_sidebarOptionStyle("/inventory")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
              {' '}
              <path
                id="icon_products"
                d="M14,19V0h2V19ZM0,19V0H2V19Zm11-5.937V0h2V13.063Zm-5,0V0h4V13.063Zm-3,0V0H5V13.063Z"
              />{' '}
            </svg>
            Inventory
          </a> */}
          <a onClick={e => toPage(e, '/orders')} className={_sidebarOptionStyle("/orders")}>
            <svg
              id="icon_Invoices"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="19.767"
              viewBox="0 0 14 19.767"
            >
              <path
                id="Path_102"
                data-name="Path 102"
                d="M14,0H2C1.4,0,1,.494,1,1.235V19.767L4,17.3l2,2.471L8,17.3l2,2.471L12,17.3l3,2.471V1.235C15,.494,14.6,0,14,0ZM12,12.355H4V9.884h8Zm0-4.942H4V4.942h8Z"
                transform="translate(-1)"
              />
            </svg>
            New Orders
          </a>
          <a onClick={e => toPage(e, '/history')} className={_sidebarOptionStyle("/history")}>
            <svg
              id="icon_calendar"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="19.767"
              viewBox="0 0 16 19.767"
            >
              <rect
                id="Rectangle_557"
                data-name="Rectangle 557"
                width="15"
                height="15"
                transform="translate(0 0.026)"
                fill="none"
              />
              <path
                id="Path_149"
                data-name="Path 149"
                d="M2,6.177V17.3H14V6.177ZM13,2.471h2c.6,0,1,.494,1,1.235V18.532c0,.741-.4,1.235-1,1.235H1c-.6,0-1-.494-1-1.235V3.706c0-.741.4-1.235,1-1.235H3V1.235C3,.494,3.4,0,4,0S5,.494,5,1.235V2.471h6V1.235C11,.494,11.4,0,12,0s1,.494,1,1.235ZM12,14.826H10V12.355h2Zm-3,0H7V12.355H9Zm3-3.706H10V8.648h2Zm-3,0H7V8.648H9ZM6,14.826H4V12.355H6Z"
                fillRule="evenodd"
              />
            </svg>
            Order History
          </a>
        </div>
      </div>
    </div>
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <nav className="nav">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={showSidebar}
          onClose={() => toggleDrawer()}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {sidebar}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          open
          anchor="left"
        >
          {sidebar}
        </Drawer>
      </Hidden>
    </nav>
  )
}

const mapStateToProps = ({ common }) => ({
  showSidebar: common.toggleDrawer
})
const mapDispatchToProps = dispatch => ({
  toggleDrawer: (open) => dispatch(toggleDrawerState(open))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidenav))
