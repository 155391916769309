import React, { Fragment, useState, useEffect } from "react";
import {
  Slider,
} from "@material-ui/core"
import Cropper from 'react-easy-crop'

import Button from 'components/common/button'
import Modal from 'components/common/modal'

import { getCroppedImg } from './imageProcessor'

import './_styles.scss'

import AddImg from './images/Referrer.png'

const ImageCropper = ({
  onCroppedImg,
  text,
  setImg, // img that is set from parent comp
  refLabel, // use to force onClick from parent
}) => {
  const [inputImg, setInputImg] = useState({
    imageSrc: null,
    cropSize: {
      width: 400,
      height: 400,
    },
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    croppedAreaPixels: null,
    croppedImage: null,
    isCropping: false,
  })

  useEffect(() => {
    setInputImg({
      ...inputImg,
      croppedImage: null,
      imageSrc: null,
    })
  }, [setImg])

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const imageDataUrl = await readFile(file)

      // apply rotation if needed
      // const orientation = await getOrientation(file)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }

      setInputImg({
        ...inputImg,
        imageSrc: imageDataUrl,
      })
    }
  }

  const onCropChange = crop => {
    setInputImg({
      ...inputImg,
      crop,
    })
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setInputImg({
      ...inputImg,
      croppedAreaPixels,
    })
  }

  const onZoomChange = zoom => {
    setInputImg({
      ...inputImg,
      zoom,
    })
  }

  const showResult = async () => {
    try {
      const {
        blob,
        base64,
      } = await getCroppedImg(
        inputImg.imageSrc,
        inputImg.croppedAreaPixels
      )
      setInputImg({
        ...inputImg,
        imageSrc: null,
        croppedImage: blob,
        isCropping: false,
      })
      onCroppedImg({ blob, base64 })
    } catch (e) {
      console.error(e)
      setInputImg({
        ...inputImg,
        isCropping: false,
      })
    }
  }

  const cancel = async () => {
    setInputImg({
      ...inputImg,
      imageSrc: null,
    })
  }

  return (
    <Fragment>
      <div className='upload-img-btn'>
        <label
          htmlFor='file-upload-input'
          className='file-upload-label'
          ref={refLabel}
        >
          <img src={inputImg.croppedImage || setImg || AddImg} />
        </label>
      </div>
      <input
        id='file-upload-input'
        label="Image"
        type="file"
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      <Modal
        open={inputImg.imageSrc}
        onClose={cancel}
        styles={{
          height: '100%',
          width: '70%',
        }}
      >
        <div className="crop-container">
          <div className="crop-container-cropper">
            <Cropper
              image={inputImg.imageSrc}
              crop={inputImg.crop}
              zoom={inputImg.zoom}
              aspect={inputImg.aspect}
              cropSize={{ width: 400, height: 400 }}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
            />
          </div>
          <div className="controls">
            <Slider
              value={inputImg.zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => onZoomChange(zoom)}
            // classes={{ container: 'slider' }}
            />
          </div>
          <div className="set-img-btn">
            <Button
              color="primary"
              variant="contained"
              onClick={showResult}
              disabled={inputImg.isCropping}
            >
              Set Image
            </Button>
            <Button
              onClick={cancel}
              classes="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default ImageCropper
