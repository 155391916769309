import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { useHistory } from 'react-router'

import { CircularProgress } from '@material-ui/core'

import Input from '../Items/Input';
import Button from 'components/common/button'
import backgroundImg from '../../../assets/images/bg_registration.jpg';

// network
import { forgotPassword } from 'services/user'
import { toggleWarning } from 'reducers/modals/actions'

const ForgotPassword = ({ openWarningModal }) => {
  const history = useHistory()

  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const { handleSubmit, register, watch, errors } = useForm()

  const onSubmit = async (payload) => {
    setSubmitting(true)
    let data
    try {
      const res = await forgotPassword(
        payload.email,
      )
      data = res.data
    } catch (err) {
      setSubmitting(false)
      return setError(err.response.data.err)
    }
    setSubmitting(false)
    // res should contain email
    if (data.result !== 'success') {
      return setError(data.err)
    }
    openWarningModal({
      callback: () => history.push('/login'),
      message: 'An email has been sent to your email address. Follow the instruction in the email to reset your password.',
    })
  };

  return (
    <div className="Registration">
      <div className="Registration-container">
        <div className="Registration-blockLeft">
          <img className="Registration-blockLeftImg" src={backgroundImg} alt="registration img" />
        </div>
        <div className="Registration-blockRight">
          <form onSubmit={handleSubmit(onSubmit)} className="Registration-form">
            <div className="Registration-title">
              <span className="Registration-titleCart">CART</span>&ORDER
                        </div>
            <div className="Registration-subTitle">
              <span className="Registration-subTitleCart">Business</span>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Email"
                  type="text"
                  error={errors.email}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('email')}
                  name="email"
                />
              </div>
            </div>
            {error && (
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}
            <div className="Registration-blockButton">
              <Button
                disabled={submitting}
                classes='success submit-button'
                type="submit"
                onClick={() => {
                }}
                disabled={false}
              >
                {submitting ? (
                  <CircularProgress color='default' size='20px' />
                ) : 'Submit'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(ForgotPassword)
