import { format } from 'date-fns'
import {
  SET_BUILDINGS,
  SELECT_BUILDING,
} from './constants'

export const initState = {
  buildings: [],
  selectedBuilding: undefined,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_BUILDINGS:
      return {
        ...state,
        buildings: payload.map(building => ({
          ...building,
          deliveryDays: building.deliveryDays.map(day => ({
            value: day,
            format: format(new Date(day), 'MMM dd, yyyy, h:mm a')
          }))
        })),
      }

    case SELECT_BUILDING:
      return {
        ...state,
        selectedBuilding: payload,
      }

    default:
      return state
  }
}
