import request from './base'

export const getOrders = (page = 0, filter = {}, anotherParams = {}) => {
  return request({
    url: '/orders',
    params: {
      page,
      filter,
      ...anotherParams,
    },
  })
}

export const getOrderDetail = (orderId) => {
  return request({
    url: `/orders/${orderId}`
  })
}

export const updateOrder = (orderId, data) => {
  return request({
    url: `/orders/${orderId}`,
    method: 'PUT',
    data
  })
}

export const packOrder = (orderId) => {
  return request({
    url: `/orders/${orderId}/pack_all`,
    method: 'POST',
  })
}

export const updateOrderItem = (orderId, orderItemId, data) => {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}`,
    method: 'PUT',
    data,
  })
}

export const confirmPayment = (orderId) => {
  return request({
    url: `/orders/${orderId}/confirm_payment`,
    method: 'POST',
  })
}
