import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core'

import {
  StyledTableThCell,
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from './styles'

import Modal from 'components/common/modal'

const ProductTable = ({
  products,
}) => {
  const classes = useStyles()
  const [selectedProduct, setSelectedProduct] = useState({})
  const [open, setOpen] = useState(false)
  const onSelectProduct = product => {
    setSelectedProduct(product)
    setOpen(true)
  }
  const ordersCell = ordersObj => {
    const orders = Object.entries(ordersObj)
    return (
      <>
        {orders.map(([order, { name, quantity }]) => (
          <p>
            <Link to={`/orders/${order}`}>{name}</Link> ({quantity})
          </p>
        ))}
      </>
    )
  }
  return (
    <Fragment>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableThCell />
              <StyledTableThCell align="left">NAME</StyledTableThCell>
              <StyledTableThCell align="left">UNIT</StyledTableThCell>
              <StyledTableThCell align="left">QUANTITY</StyledTableThCell>
              <StyledTableThCell align="left">ORDERS (QTY)</StyledTableThCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.filter(p => p.quantity > 0).map((product, i) => (
              <StyledTableRow key={product._id} onClick={() => onSelectProduct(product)}>
                <StyledTableCell component="th" scope="row">
                  {i + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{product.name}</StyledTableCell>
                <StyledTableCell align="left">{product.pricePerUnit ? product.unit : product.size}</StyledTableCell>
                <StyledTableCell align="left">{product.quantity}</StyledTableCell>
                <StyledTableCell align="left" colSpan="1">{ordersCell(product.orders)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <img src={selectedProduct.image} alt='selected product' />
        <div>
          <p>{selectedProduct.name}</p>
          <p>${selectedProduct.price} - {selectedProduct.size}</p>
        </div>
      </Modal>
    </Fragment>
  )
}

export default ProductTable
