import React from 'react'

import './styles.scss'

const Button = ({ children, classes = '', onClick, disabled }) => {
  return (
    <button
      className={`button ${classes}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button