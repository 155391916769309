import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../../../common/button'
import Modal from '../../../../common/modal'
import NearbyStoreList from '../modals/nearby-store-list'

const HomeSignupLeftPanel = () => {
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  const toRegister = () => {
    history.push('/signup')
  }
  const toLogin = () => {
    history.push('/login')
  }
  return (
    <Fragment>
      <h1>Partner with Cart & Order and grow your business</h1>
      <div className='button-list'>
        <Button classes='success' onClick={toRegister} >Register</Button>
        <Button onClick={toLogin} classes='white'>Login</Button>
      </div>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      // styles={{
      //   maxHeight: '83vh',
      // }}
      >
        <NearbyStoreList />
      </Modal>
    </Fragment>
  )
}

export default HomeSignupLeftPanel