import React from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router'

import Header from 'components/Navigation/Header/Header'
import Sidenav from 'components/Navigation/Sidenav/Sidenav'
import OpenOrder from 'components/Main/OpenOrder/OpenOrder'
import OrderHistory from 'components/Main/OrderHistory'
import Receipt from 'components/Main/Receipt/Receipt'
import Profile from 'components/Main/Profile/Profile'
import NotFound from 'components/Navigation/NotFound'
import Login from 'components/Initial/Login/Login'
import Signup from 'components/Initial/Signup/Signup'
import ForgotPassword from 'components/Initial/ForgotPassword/ForgotPassword'
import ResetPassword from 'components/Initial/ResetPassword/ResetPassword'
import Landing from 'components/Initial/Landing/Landing'
import ProtectedRoute from 'components/Navigation/ProtectedRoute'
import history from 'utils/history'
import Loader from 'components/Navigation/Loader/Loader'
import Snackbar from 'components/common/snackbar'
import WarningModal from 'components/common/modal/WarningModal'

import { loadDataInit } from 'utils/state_init'
import { setAxiosDispatch } from 'utils/axios'

class App extends React.Component {
  constructor(props) {
    super(props)
    loadDataInit(props.dispatch)
    setAxiosDispatch(props.dispatch)
  }
  render() {
    return (
      <Router history={history}>
        <>
          <Loader />
          <Switch>
            <Route path="/home" component={Landing} strict></Route>
            <Route path="/login" component={Login} strict></Route>
            <Route path="/signup" component={Signup} strict></Route>
            <Route path="/forgot-password" component={ForgotPassword} strict></Route>
            <Route path="/reset-password" component={ResetPassword} strict></Route>
            <Route path="/">
              <Sidenav />
              <main className="container-fluid">
                <Header />
                <Switch>
                  {/* <ProtectedRoute path="/dashboard" component={Dashboard} strict></ProtectedRoute>
                  <ProtectedRoute path="/inventory" component={Inventory} strict exact></ProtectedRoute> */}
                  <ProtectedRoute path="/orders" component={OpenOrder} strict></ProtectedRoute>
                  <ProtectedRoute path="/receipt/:id" component={Receipt} strict></ProtectedRoute>
                  <ProtectedRoute path="/history" component={OrderHistory} strict></ProtectedRoute>
                  <ProtectedRoute path="/profile" component={Profile} strict></ProtectedRoute>
                  <Route path="/" exact render={() => <Redirect to="orders" />}></Route>
                  <ProtectedRoute path="*" component={NotFound} exact={true} strict></ProtectedRoute>
                </Switch>
              </main>
            </Route>
          </Switch>
          <Snackbar />
          <WarningModal />
        </>
      </Router>
    )
  }
}

export default connect()(App)
