import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { format as formatDate } from 'date-fns'

import { Menu, MenuItem } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import StatusBadge from 'components/common/status-badge'
import ProgressButton from 'components/common/button/ProgressButton'

import {
  getOrderDetail,
  updateOrder,
  updateOrderItem,
  confirmPayment,
} from 'services/orders'

import OrderItem from './OrderItem'

import { openToast } from 'reducers/toast/actions'
import { renderMoney, ORDER_ACTIONS, getMappedOrderStatus } from 'utils/helper'

const statuses = [
  {
    name: 'Packing',
    value: 'PACKING',
  },
  {
    name: 'Packed',
    value: 'PACKED',
  },
  {
    name: 'Delivered',
    value: 'DELIVERED',
  },
]

function OrderDetail({ match, history, buildings, dispatch }) {
  const [order, setOrder] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [buildingsObj, setBuildingsObj] = useState()

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (buildings.length > 0) {
      onBuildingsLoaded()
    }
  }, [buildings])
  const onBuildingsLoaded = async () => {
    const buildingsObj = buildings.reduce((acc, building) => {
      acc[building._id] = building
      return acc
    }, {})
    setBuildingsObj(buildingsObj)
    const order = await fetchData()
    handleData(order, buildingsObj)
  }

  const init = async () => {
    fetchData()
  }

  const handleData = (order, buildings = buildingsObj) => {
    if (!order._id) return
    const data = {
      ...order,
      fee: Math.round((order.percentageFee + order.fixedFee) * 100) / 100,
      orderDateFormatted: formatDate(new Date(order.createdAt), 'MMMM dd, yyyy'),
    }
    if (buildings) {
      let deliveryTime = order.deliveryTime ? formatDate(new Date(order.deliveryTime), 'MM/dd/yyyy, h:mm a') : ''
      if (deliveryTime) {
        const dropoffDuration = buildings[order.building]?.dropoffDuration
        if (dropoffDuration) {
          const toTime = new Date(order.deliveryTime)
          toTime.setMinutes(toTime.getMinutes() + dropoffDuration)
          deliveryTime += ` - ${formatDate(toTime, 'h:mm a')}`
        }
      }
      data.deliveryTime = deliveryTime
    }
    setOrder(data)
  }

  const fetchData = async () => {
    const orderId = match.params.id
    let res
    try {
      res = (await getOrderDetail(orderId)).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const order = res.data
    if (!order) return
    return order
  }

  const updateOrderStatus = async status => {
    const orderId = match.params.id
    let res
    try {
      res = (await updateOrder(orderId, {
        status,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const order = res.data
    handleData(order)
    setAnchorEl(null)
    dispatch(openToast({
      message: 'Order Updated!',
      type: 'success',
    }))
  }

  const onItemUpdate = async (index, data) => {
    if (!order.orderItems[index]) return
    const orderId = match.params.id
    const orderItemId = order.orderItems[index]._id
    let res
    try {
      res = (await updateOrderItem(orderId, orderItemId, data)).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const orderItem = res.data
    order.orderItems[index] = orderItem
    order.finalSubtotal = orderItem.order.finalSubtotal
    order.finalTax = orderItem.order.finalTax
    order.finalTotal = orderItem.order.finalTotal
    order.status = orderItem.order.status
    setOrder({
      ...order,
      orderItems: order.orderItems,
    })
    dispatch(openToast({
      message: 'Item Updated!',
      type: 'success',
    }))
  }

  const onConfirmPayment = async () => {
    let res
    try {
      res = (await confirmPayment(order._id)).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const updatedOrder = res.data
    setOrder({
      ...order,
      paymentStatus: updatedOrder.paymentStatus,
    })
    dispatch(openToast({
      message: 'Payment Confirmed!',
      type: 'success',
    }))
  }

  const isConfirmPaymentEnabled = order => {
    return order.status !== 'NEW' && order.paymentStatus !== 'COMPLETED'
  }
  return (
    <div className="OrderDetail container-fluid">
      <div className="heading">
        <ArrowBackIcon className="back-btn" onClick={history.goBack} />
        <h4>Order Detail</h4>
        <div className="order-actions">
          <ProgressButton
            classes="success"
            disabled={!isConfirmPaymentEnabled(order)}
            onClick={onConfirmPayment}
          >
            Confirm Payment
          </ProgressButton>
        </div>
        {/* <div className="search input-group">
          <div className="input-group-prepend">
            <span className="" id="search-order-detail">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.997"
                height="17"
                viewBox="0 0 16.997 17"
              >
                <path
                  id="noun_Search_2102816_2_"
                  data-name="noun_Search_2102816 (2)"
                  d="M12.282,18.559a6.266,6.266,0,0,0,3.911-1.366l5.6,5.6a.708.708,0,1,0,1-1l-5.6-5.6a6.283,6.283,0,1,0-4.912,2.368ZM8.842,8.841a4.865,4.865,0,1,1,0,6.879A4.832,4.832,0,0,1,8.842,8.841Z"
                  transform="translate(-6.004 -6)"
                  fill="#7b8a96"
                />
              </svg>
            </span>
          </div>
          <input
            type="text"
            className=""
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-order-detail"
          />
        </div> */}

      </div>
      <div className="invoice-detail">
        <div className="detail-row">
          <div className="detail-col">
            <p>
              Order/Invoice ID: <span>{order.shortId || order._id}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Order Date: <span>{order.orderDateFormatted}</span>
            </p>
          </div>
          <div className="detail-col space-between">
            <p>
              Order Status:
            </p>
            <span style={{ display: 'inline-block' }}>
              {/* <StatusBadge content={order.status} /> */}
              <div className="dropdown">
                <button
                  className={`btn btn-secondary dropdown-toggle ${order.status}`}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={event => setAnchorEl(event.currentTarget)}
                >
                  {getMappedOrderStatus(order.status)}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.678"
                    height="4.886"
                    viewBox="0 0 7.678 4.886"
                  >
                    <path
                      id="Path_360"
                      data-name="Path 360"
                      d="M6.439,9.486,2.6,5.606,3.6,4.6,6.439,7.474,9.282,4.6l1,1.006Z"
                      transform="translate(-2.6 -4.6)"
                      fill="#fff"
                    />
                  </svg>
                </button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {ORDER_ACTIONS.map(action => (
                    <MenuItem onClick={() => updateOrderStatus(action.value)}>{action.name}</MenuItem>
                  ))}
                </Menu>
              </div>
            </span>
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-col">
            <p>
              Customer: <span>{order.firstName} {order.lastName}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Payment Method: <span className="text-uppercase">{order.paymentMethod}</span>
            </p>
          </div>
          <div className="detail-col space-between">
            <p>
              Order Amount: <span>${order.total}</span>&nbsp;
            </p>
            {order.paymentStatus === 'COMPLETED' && (
              <StatusBadge content='PAID' />
            )}
          </div>
        </div>
        <div className="detail-row order_two_data">
          <div className="detail-col">
            <p>
              Customer Email: <span>{order.email}</span>
            </p>
            <p>
              Customer Phone: <span>{order.phone}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Delivery Date: <span>{order.deliveryTime}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Cart&Order Fee: <span>${order.fee}</span>
            </p>
            <p>
              Credit Card Fee: <span>${order.ccFee}</span>
            </p>
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-col" style={{
            flex: 'unset',
            maxWidth: 'unset',
          }}>
            <p>
              Note: <span>{order.note}</span>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="order-actions">
        <div className="order-action">
          <Button classes="success" onClick={packAll}>Pack All</Button>
        </div>
      </div> */}
      <div className="invoice-detail">
        <table className="table products-list">
          <thead>
            <tr>
              <th colSpan="1">Status</th>
              <th colSpan="2">Product</th>
              <th colSpan="1">Price</th>
              <th colSpan="1">Size</th>
              <th colSpan="1">Weight</th>
              <th colSpan="1">QTY</th>
              <th colSpan="1">Tax</th>
              <th colSpan="1">Total</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {order.orderItems && order.orderItems.filter(i => i.quantity > 0).map((item, n) => {
              return (
                <OrderItem item={item} key={n} index={n} updateItem={onItemUpdate} />
              )
            })}
          </tbody>
        </table>
        <div className="total-amounts">
          <p>
            Sub-total: <span>
              ${renderMoney(order.finalSubtotal || order.subtotal || order.total)}
            </span>
          </p>
          <p>
            Shipping Fee: <span>${renderMoney(order.shippingFee)}</span>
          </p>
          <p>
            Taxes: <span>
              ${renderMoney(order.finalTax || order.tax)}
            </span>
          </p>
          <h4>
            Total: <span>
              ${renderMoney(order.finalTotal || order.total)}
            </span>
          </h4>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
})

export default connect(mapStateToProps)(OrderDetail)
