import React, { useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Menu, MenuItem } from '@material-ui/core'
import {
  Create as CreateIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from '@material-ui/icons'

import Input from 'components/common/inputs/style1'
import DataChanged from 'components/common/data-changed'
import { getMappedItemStatus, renderMoney } from 'utils/helper'

const statusMapping = {
  PROCESSING: 'Processing',
  PACKED: 'Packed',
  OUT_OF_STOCK: 'Out of stock',
  REMOVED: 'Removed',
}
const statuses = Object.entries(statusMapping)
  .filter(([key]) => key !== 'REMOVED')
  .map(([key, value]) => ({
    name: value,
    value: key,
  }))
// const statuses = [
//   {
//     name: statusMapping['PENDING'],
//     value: 'PENDING',
//   },
//   {
//     name: statusMapping['PACKED'],
//     value: 'PACKED',
//   },
//   {
//     name: statusMapping['OUT_OF_STOCK'],
//     value: 'OUT_OF_STOCK',
//   }
// ]

const ActionItem = ({ Icon, color, onClick }) => {
  return (
    <div onClick={onClick} className={`item-action ${color}`}>
      {Icon}
    </div>
  )
}

const NotEditingItem = ({ item, index, onEditing, updateStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const onCloseMenu = () => {
    setAnchorEl(null)
  }
  const onStatusChanged = status => {
    updateStatus(status)
    onCloseMenu()
  }
  return <tr key={index}>
    <td colSpan="1">
      <div className="dropdown">
        <button
          className={`btn btn-secondary dropdown-toggle ${item.status}`}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          {getMappedItemStatus(item.status)}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.678"
            height="4.886"
            viewBox="0 0 7.678 4.886"
          >
            <path
              id="Path_360"
              data-name="Path 360"
              d="M6.439,9.486,2.6,5.606,3.6,4.6,6.439,7.474,9.282,4.6l1,1.006Z"
              transform="translate(-2.6 -4.6)"
              fill="#fff"
            />
          </svg>
        </button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onCloseMenu}
        >
          {statuses.map(status => (
            <MenuItem onClick={() => onStatusChanged(status.value)}>{status.name}</MenuItem>
          ))}
        </Menu>
      </div>
    </td>
    <td colSpan="2">
      <h2>{item.product.name}</h2>
    </td>
    <td colSpan="1">
      ${renderMoney(item.price)}
    </td>
    <td>
      <DataChanged
        oldData={item.unitValue}
        newData={item.finalUnitValue}
      />
      &nbsp;{item.unit}
    </td>
    <td colSpan="1">{item.weight && `${item.weight} ${item.unit}`}</td>
    <td colSpan="1">{item.quantity}</td>
    <td colSpan="1">${renderMoney(item.finalTax ?? item.tax)}</td>
    <td colSpan="1">${renderMoney(item.finalTotal ?? item.total)}</td>
    <td>
      <div className='item-actions'>
        <ActionItem
          Icon={<CreateIcon />}
          onClick={onEditing}
        />
      </div>
    </td>
  </tr>
}

const EditingItem = ({ item, index, cancelEdit, updateItem, updateStatus }) => {
  const weightEdit = item.pricePerUnit
  const [anchorEl, setAnchorEl] = useState(null)
  const [tempItem, setTempItem] = useState({ ...item })
  const { register, errors } = useForm({});
  const onCloseMenu = () => {
    setAnchorEl(null)
  }
  const onStatusChanged = status => {
    updateStatus(status)
    onCloseMenu()
  }
  const onFieldChange = (field, value) => {
    setTempItem({
      ...tempItem,
      [field]: value,
    })
  }
  const submitEdit = () => {
    const payload = {
      status: tempItem.status !== item.status ? tempItem.status : undefined,
      weight: tempItem.weight !== item.weight ? tempItem.weight : undefined,
      quantity: tempItem.quantity !== item.quantity ? tempItem.quantity : undefined,
      unitValue: tempItem.unitValue !== item.unitValue ? tempItem.unitValue : undefined,
    }
    updateItem(payload)
  }
  return (
    <tr key={index}>
      <td colSpan="1">
        <div className="dropdown">
          <button
            className={`btn btn-secondary dropdown-toggle ${tempItem.status}`}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            {getMappedItemStatus(tempItem.status)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7.678"
              height="4.886"
              viewBox="0 0 7.678 4.886"
            >
              <path
                id="Path_360"
                data-name="Path 360"
                d="M6.439,9.486,2.6,5.606,3.6,4.6,6.439,7.474,9.282,4.6l1,1.006Z"
                transform="translate(-2.6 -4.6)"
                fill="#fff"
              />
            </svg>
          </button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseMenu}
          >
            {statuses.map(status => (
              <MenuItem onClick={() => onStatusChanged(status.value)}>{status.name}</MenuItem>
            ))}
          </Menu>
        </div>
      </td>
      <td colSpan="2">
        <h2>{tempItem.product.name}</h2>
      </td>
      <td colSpan="1">${renderMoney(tempItem.price)}</td>
      <td colSpan="1">{tempItem.unitValue} {tempItem.unit}</td>
      {weightEdit ? (
        <>
          <td class='d-flex' colSpan="1">
            <Input
              type="number"
              min="0"
              placeholder='Weight'
              inputRef={register({
                required: true,
              })}
              name="weight"
              defaultValue={tempItem.weight}
              className={errors.unitValue && 'error'}
              onChange={e => onFieldChange('weight', e.target.value)}
            />
            {tempItem.unit}
          </td>
          <td colSpan="1">{tempItem.quantity}</td>
        </>
      ) : (
        <>
          <td colSpan="1"></td>
          <td class='d-flex' colSpan="1">
            <Input
              type="number"
              step="1"
              min="1"
              placeholder='Quantity'
              inputRef={register({
                required: true,
              })}
              name="quantity"
              defaultValue={tempItem.quantity}
              className={errors.unitValue && 'error'}
              onChange={e => onFieldChange('quantity', e.target.value)}
            />
          </td>
        </>
      )}
      <td colSpan="1">${renderMoney(tempItem.finalTax ?? tempItem.tax)}</td>
      <td colSpan="1">${renderMoney(tempItem.finalTotal ?? tempItem.total)}</td>
      <td colSpan="1">
        <div className='item-actions'>
          <ActionItem
            Icon={<CheckIcon />}
            color='green'
            onClick={submitEdit}
          />
          <ActionItem
            Icon={<ClearIcon />}
            color='red'
            onClick={cancelEdit}
          />
        </div>
      </td>
    </tr>
  )
}
const OrderItem = ({ item, updateItem, index }) => {
  const [editing, setEditing] = useState(false)
  const editable = item.pricePerUnit

  const onUpdateItem = (data) => {
    setEditing(false)
    updateItem(index, data)
  }
  const onUpdateStatus = status => {
    setEditing(false)
    updateItem(index, { status })
  }
  return editing ? <EditingItem
    item={item}
    updateItem={onUpdateItem}
    updateStatus={onUpdateStatus}
    cancelEdit={() => setEditing(false)}
    index={index}
  /> : <NotEditingItem
    item={item}
    updateItem={onUpdateItem}
    updateStatus={onUpdateStatus}
    onEditing={() => setEditing(true)}
    index={index}
    editable={editable}
  />
}

export default OrderItem
