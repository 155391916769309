// use to initialize state
import { setUser } from 'reducers/user/actions'
import { setCategories, setSubcategories } from 'reducers/category/actions'
import { loadData } from 'services/user'
import { getBuildings } from 'services/buildings'
import { setBuildings } from 'reducers/buildings/actions'
export const loadUser = async dispatch => {
  // load user
  let user = localStorage.getItem('user')
  if (user) {
    try {
      user = JSON.parse(user)
    } catch (err) {
      localStorage.removeItem('user')
      return
    }
    // dispatch
    await dispatch(setUser(user))
    // load data then
    loadRelatedData(dispatch, user)
  }
}

/**
 * 
 * @param {function} dispatch 
 * @param {object} user optional 
 */
export const loadRelatedData = async (dispatch, user) => {
  if (!user) {
    user = localStorage.getItem('user')
    user = JSON.parse(user)
  }
  let userData
  try {
    userData = (await loadData(user._id)).data.data
  } catch (err) {
    return console.error(err)
  }
  const categories = userData.categories
  const subcategories = userData.subcategories || []
  delete userData.store
  delete userData.categories

  dispatch(setCategories(categories.map(category => ({
    ...category,
    name: category.label,
    value: category.label,
  }))))
  dispatch(setSubcategories(subcategories.map(subcategory => ({
    ...subcategory,
    name: subcategory.label,
    value: subcategory.label,
  }))))
  dispatch(setUser(userData))
}

const loadBuildings = async (dispatch) => {
  const buildingsRes = (await getBuildings()).data.data
  dispatch(setBuildings(buildingsRes))
}

export const loadDataInit = async dispatch => {
  loadUser(dispatch)
  loadBuildings(dispatch)
}