import { SET_USER, CLEAR_USER } from './constants'

export const initState = {
  firstName: undefined,
  lastName: undefined,
  username: undefined,
  email: undefined,
  phoneNumber: undefined,
  address: undefined,
  storeName: undefined,
  storeType: undefined,
  token: undefined,
  createdAt: undefined,
  status: undefined,
  stripeAccount: undefined,
  _id: undefined,
  building: undefined,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      let token = payload.token
      delete payload.token
      localStorage.setItem('user', JSON.stringify(payload))
      if (token) {
        localStorage.setItem('token', token)
      } else {
        token = localStorage.getItem('token')
      }
      return { ...state, ...payload, token }

    case CLEAR_USER:
      localStorage.clear('user')
      return { ...initState }

    default:
      return state
  }
}