import React, { useState, useEffect, useCallback } from 'react'

import {
  Box,
} from "@material-ui/core"
import { useForm } from 'react-hook-form'

import Input from 'components/Initial/Items/Input'
import Dropdown from 'components/Initial/Items/Dropdown'
import Button from 'components/common/button'

import { ORDER_ACTIONS } from 'utils/helper'

const statuses = [
  {
    name: 'All',
    value: -1,
  },
  ...ORDER_ACTIONS,
  {
    name: 'Completed',
    value: 'COMPLETED'
  },
  {
    name: 'Failed',
    value: 'CANCELLED',
  }
]
const defaultFormValue = {
  status: statuses[0].value
}
const InventoryFilter = ({ onFilter }) => {
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedSubcategory, setSelectedSubcategory] = useState()

  const { register, watch, control } = useForm({ defaultValues: defaultFormValue })

  const onSearch = useCallback(() => {
    const filter = {
      shortId: watch('shortId') || undefined,
      status: watch('status') === -1 ? undefined : watch('status'),
    }
    onFilter && onFilter(filter, 0)
  }, [onFilter, watch])
  useEffect(() => {
    if (selectedCategory || selectedSubcategory) {
      onSearch()
    }
  }, [selectedCategory, selectedSubcategory, onSearch])

  return (
    <Box component="div" mb={2} className="inventory-filter">
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        height="100%"
        py={2.5}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="font-26 text-dark-blue"
        >
          Order History
        </Box>
      </Box>
      <div className='i-f-container'>
        <div className='i-f-form'>
          <div className='i-f-form-row'>
            <Input
              placeholder='Order ID'
              name='shortId'
              refInput={register({
                required: true,
              })}
              value={watch('shortId')}
            />
            <Dropdown
              label='Status'
              name='status'
              value={watch('status')}
              control={control}
              items={statuses}
              styles={{
                width: '25%'
              }}
            />
          </div>
          <div className='i-f-form-row-submit'>
            <Button className='clr-btn'>
              Clear Search
            </Button>
            <Button
              classes='submit-btn success'
              onClick={onSearch}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default InventoryFilter;
