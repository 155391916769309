import React from 'react';

import { CircularProgress } from '@material-ui/core'

import './styles.scss';

const Button = ({ children, onClick, type, disabled, classes, submitting }) => {
  return (
    <button
      className={`button ${classes || ''}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <div className="Button-text">
        {submitting ? (
          <CircularProgress color='default' size='20px' />
        ) : children}
      </div>
    </button>
  );
}

export default Button;
