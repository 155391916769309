import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  TableContainer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core'

import {
  StyledTableThCell,
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from './styles'

import StatusBadge from 'components/common/status-badge'
import MoreIcon from '@material-ui/icons/MoreVert'

import { renderMoney } from 'utils/helper'

const OrderTable = ({
  orders,
  onActionClick,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableThCell />
            <StyledTableThCell align="left">ORDER ID</StyledTableThCell>
            <StyledTableThCell align="left">CUSTOMER NAME</StyledTableThCell>
            <StyledTableThCell align="left">DELIVERY TIME</StyledTableThCell>
            <StyledTableThCell align="left">TOTAL</StyledTableThCell>
            <StyledTableThCell align="left">ORDER STATUS</StyledTableThCell>
            <StyledTableThCell align="left">ACTION</StyledTableThCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, i) => (
            <StyledTableRow key={order._id} onClick={() => history.push(`/receipt/${order._id}`)}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell align="left">{order.shortId}</StyledTableCell>
              <StyledTableCell align="left">{order.customerName}</StyledTableCell>
              <StyledTableCell align="left">{order.deliveryTime}</StyledTableCell>
              <StyledTableCell align="left">${renderMoney(order.finalTotal || order.total)}</StyledTableCell>
              <StyledTableCell align="left">
                <StatusBadge content={order.status} />
              </StyledTableCell>
              <StyledTableCell align="left">
                {' '}
                <IconButton
                  aria-label="display more actions"
                  edge="end"
                  color="inherit"
                  onClick={e => onActionClick(e, i)}
                >
                  <MoreIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderTable
