import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import Signup from './components/signup'
import Introduction from './components/introduction'
import HowItWorks from './components/how-it-works'
import Footer from './components/footer'

import './_Landing.scss'

const Landing = ({ user }) => {
  if (user && user.token) return <Redirect to='/dashboard' />
  return (
    <React.Fragment>
      <div className='home-page'>
        <div className='home-page-signup'>
          <Signup />
        </div>
        <div className='home-page-intro'>
          <Introduction />
        </div>
        <div className='home-page-how-it-works'>
          <HowItWorks />
        </div>
        {/* <Subscription /> */}
        <Footer />
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Landing)
