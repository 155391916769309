
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import { format as formatDate } from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Menu,
  MenuItem,
} from '@material-ui/core';

import OpenOrderFilter from './Filter/Filter'
import Checkbox from 'components/Initial/Items/Checkbox'

import OrderTable from './tables/OrderTable'
import ProductTable from './tables/ProductTable'

import {
  getOrders,
  updateOrder,
} from 'services/orders'

import { ORDER_ACTIONS } from 'utils/helper'

import './_styles.scss'

const OpenOrder = ({
  buildings,
}) => {
  const [orders, setOrders] = useState([])
  const [productItems, setProductItems] = useState([]) // { ...product, quantity }
  const [selectedOrderIndex, setSelectedOrderIndex] = useState()
  const [filterState, setFilterState] = useState({})
  const [page, setPage] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null) // action dropdown
  const [buildingsObj, setBuildingsObj] = useState()
  const [showItems, setShowItems] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (buildings.length > 0) {
      const buildingsObj = buildings.reduce((acc, building) => {
        acc[building._id] = building
        return acc
      }, {})
      setBuildingsObj(buildingsObj)
    }
  }, [buildings])

  const fetchData = async (filter = filterState, p = page, includeProducts = true) => {
    let res
    try {
      res = (await getOrders(p, {
        ...filter,
        open: true,
      }, {
        includeProducts,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const orders = res.data.map(order => {
      return transformOrder(order)
    })
    setOrders(orders)
    setFilterState(filter)
    setPage(p + 1)
    if (includeProducts) {
      const productsObj = {}
      orders.forEach(order => {
        order.orderItems.forEach(orderItem => {
          if (productsObj[orderItem.product._id]) {
            productsObj[orderItem.product._id].quantity += orderItem.quantity
            productsObj[orderItem.product._id].orders = {
              ...productsObj[orderItem.product._id].orders,
              [order._id]: {
                name: `${order.firstName} ${order.lastName}`,
                quantity: orderItem.quantity,
              },
            }
          } else {
            productsObj[orderItem.product._id] = {
              ...orderItem.product,
              orders: {
                [order._id]: {
                  name: `${order.firstName} ${order.lastName}`,
                  quantity: orderItem.quantity,
                },
              },
              quantity: orderItem.quantity,
            }
          }
        })
      })
      setProductItems(Object.values(productsObj))
    }
  }

  const transformOrder = order => {
    let deliveryTime = order.deliveryTime ? formatDate(new Date(order.deliveryTime), 'MM/dd/yyyy, h:mm a') : ''
    if (deliveryTime) {
      const dropoffDuration = buildingsObj[order.building]?.dropoffDuration
      if (dropoffDuration) {
        const toTime = new Date(order.deliveryTime)
        toTime.setMinutes(toTime.getMinutes() + dropoffDuration)
        deliveryTime += ` - ${formatDate(toTime, 'h:mm a')}`
      }
    }
    return {
      ...order,
      fee: Math.round((order.percentageFee + order.fixedFee) * 100) / 100,
      customerName: `${order.firstName} ${order.lastName}`,
      deliveryTime,
    }
  }
  const onActionClick = (e, index) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setSelectedOrderIndex(index)
  }
  const toOrderDetail = id => {
    history.push(`/receipt/${id}`)
  }

  const updateOrderStatus = async (status) => {
    let order = orders[selectedOrderIndex]
    if (!order) return
    let updatedOrder
    try {
      const res = (await updateOrder(order._id, {
        status,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
      updatedOrder = res.data
    } catch (err) {
      return console.error(err)
    }
    orders[selectedOrderIndex] = transformOrder(updatedOrder)
    setOrders([...orders])
    setAnchorEl(null)
  }

  return (
    <Box className="open-order-page">
      <Box component="div" mb={2} px={3}>
        <OpenOrderFilter onFilter={fetchData} />
      </Box>
      <Box component="div" mb={2} px={3}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="100%"
          py={2.5}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="font-26 text-dark-blue"
          >
            New Order
          </Box>
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            className="font-26 text-dark-blue"
          >
            <Checkbox
              label='Show Items'
              checked={showItems}
              onClick={() => setShowItems(!showItems)}
            />
          </Box>
        </Box>
        {!showItems ? (<OrderTable
          orders={orders}
          onActionClick={onActionClick}
        />) : (<ProductTable
          products={productItems}
        />)}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {ORDER_ACTIONS.map(action => (
            <MenuItem onClick={() => updateOrderStatus(action.value)}>{action.name}</MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
}


const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
})

export default connect(mapStateToProps)(OpenOrder)
