import React from 'react';

import './Checkbox.scss';

const Checkbox = ({
  label,
  checked,
  name,
  onClick,
}) => {
  return (
    <div className="Checkbox" onClick={onClick}>
      <span className="Checkbox-customBorder" />
      <input
        value={checked}
        checked={checked}
        onChange={() => { }}
        className="Checkbox-custom"
        name={name}
        type="checkbox"
      />
      <label htmlFor="checkbox" className="Checkbox-customLabel">
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
