import {
  TOGGLE_NEARBY_STORE,
  TOGGLE_WARNING,
  TOGGLE_PICKUP_CALENDAR,
} from './constants'

export const toggleWarning = ({ open, withCancel, callback, message, }) => {
  return {
    type: TOGGLE_WARNING,
    payload: {
      open, withCancel, callback, message,
    },
  }
}
