import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'

import { setAfterLogin } from 'reducers/user/actions'

const ProtectedRoute = ({ user, setUserAfterLogin, component: Component, ...rest }) => {
    console.log('ProtectedRoute', { user })
    return (
        <Route {...rest} render={(props) => (
            user && user.token
                ? <Component {...props} />
                : <Redirect to='/home' />
        )} />)
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}
const mapDispatchToProps = dispatch => ({
    setUserAfterLogin: user => dispatch(setAfterLogin(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
