import request from './base'

export const login = (email, password) => {
  return request({
    url: '/login',
    method: 'POST',
    data: {
      email, password
    }
  })
}

export const signup = (data) => {
  return request({
    url: '/createUser',
    method: 'POST',
    data,
  })
}

export const loadData = userId => {
  return request({
    url: `/users/${userId}`,
  })
}

export const linkPayment = () => {
  return request({
    url: `/users/link_payment`,
    method: 'POST'
  })
}

export const updateProfile = (userId, data) => {
  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data
  })
}

export const forgotPassword = (email) => {
  return request({
    url: `/users/forgot_password`,
    method: 'POST',
    data: { email }
  })
}

export const resetPassword = (email, token, newPassword) => {
  return request({
    url: `/users/reset_password`,
    method: 'POST',
    data: { email, token, newPassword }
  })
}
