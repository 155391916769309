
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import { format as formatDate } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { withStyles, makeStyles, styled } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@material-ui/core';

import StatusBadge from 'components/common/status-badge';
import Filter from './Filter/Filter'

import {
  getOrders,
} from 'services/orders'

import './_styles.scss'

const StyledTableThCell = styled(TableCell)({
  fontFamily: '"Source Sans Pro", sans-serif',
  fontWeight: 700,
  fontSize: '13px',
  color: '#7B8A96',
})

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edf1f4',
    color: '#7b8a96',
  },
  body: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fa',
    },
    color: '#4d4f5c',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dedede',
    }
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 900,
  },
  tableContainer: {
    padding: 15,
  },
});

const OrderHistory = ({
  buildings,
}) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(0)
  const [filterState, setFilterState] = useState({})
  const [buildingsObj, setBuildingsObj] = useState({})

  const history = useHistory()

  useEffect(() => {
    if (buildings.length > 0) {
      onBuildingsLoaded()
    }
  }, [buildings])
  const onBuildingsLoaded = async () => {
    const buildingsObj = buildings.reduce((acc, building) => {
      acc[building._id] = building
      return acc
    }, {})
    setBuildingsObj(buildingsObj)
  }
  const fetchData = async (filter = filterState, p = page) => {
    let res
    try {
      res = (await getOrders(p, {
        ...filter,
        // open: false,
      })).data
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      return console.error(err)
    }
    const orders = res.data.map(order => {
      return transformOrder(order)
    })
    setOrders(orders)
    setFilterState(filter)
    setPage(p + 1)
    return res.data
  }

  const transformOrder = (order, buildings = buildingsObj) => {
    let deliveryTime = order.deliveryTime ? formatDate(new Date(order.deliveryTime), 'MM/dd/yyyy, h:mm a') : ''
    if (deliveryTime) {
      const dropoffDuration = buildings[order.building]?.dropoffDuration
      if (dropoffDuration) {
        const toTime = new Date(order.deliveryTime)
        toTime.setMinutes(toTime.getMinutes() + dropoffDuration)
        deliveryTime += ` - ${formatDate(toTime, 'h:mm a')}`
      }
    }
    return {
      ...order,
      fee: Math.round((order.percentageFee + order.fixedFee) * 100) / 100,
      customerName: `${order.firstName} ${order.lastName}`,
      deliveryTime,
    }
  }
  const toOrderDetail = id => {
    history.push(`/receipt/${id}`)
  }

  return (
    <Box className="order-history-page">
      <Box component="div" mb={2} px={3}>
        <Filter onFilter={fetchData} />
      </Box>
      <Box component="div" mb={2} px={3}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="100%"
          py={2.5}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="font-26 text-dark-blue"
          >
            Order History
          </Box>
        </Box>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableThCell />
                <StyledTableThCell align="left">ORDER ID</StyledTableThCell>
                <StyledTableThCell align="left">CUSTOMER NAME</StyledTableThCell>
                <StyledTableThCell align="left">DELIVERY TIME</StyledTableThCell>
                <StyledTableThCell align="left">TOTAL</StyledTableThCell>
                <StyledTableThCell align="left">ORDER STATUS</StyledTableThCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, i) => (
                <StyledTableRow key={order._id} onClick={() => toOrderDetail(order._id)}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left">{order.shortId}</StyledTableCell>
                  <StyledTableCell align="left">{order.customerName}</StyledTableCell>
                  <StyledTableCell align="left">{order.deliveryTime}</StyledTableCell>
                  <StyledTableCell align="left">${order.total.toFixed(2)}</StyledTableCell>
                  <StyledTableCell align="left">
                    <StatusBadge content={order.status} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
})
export default connect(mapStateToProps)(OrderHistory)
