import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import qs from 'query-string'
import _ from 'lodash'

import { CircularProgress } from '@material-ui/core'

import Input from '../Items/Input';
import Button from 'components/common/button'
import backgroundImg from '../../../assets/images/bg_registration.jpg';

import { resetPassword } from 'services/user'
import { openToast } from 'reducers/toast/actions'


const ResetPassword = ({ location, history, dispatch }) => {
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const { handleSubmit, register, watch, errors } = useForm()

  const onSubmit = async (payload) => {
    const { newPassword, confirmPassword } = payload
    if (newPassword !== confirmPassword) {
      return setError('Password not match')
    }
    const {
      email,
      token
    } = qs.parse(location.search)
    setSubmitting(true)
    let data
    try {
      const res = await resetPassword(
        email,
        token,
        newPassword,
      )
      data = res.data
    } catch (err) {
      setSubmitting(false)
      return setError(err.response.data.err)
    }
    setSubmitting(false)
    // res should contain email
    if (data.result !== 'success') {
      return setError(data.err)
    }
    dispatch(openToast({
      message: 'Password updated!',
      type: 'success',
    }))
    history.push('/login')
  };

  return (
    <div className="Registration">
      <div className="Registration-container">
        <div className="Registration-blockLeft">
          <img className="Registration-blockLeftImg" src={backgroundImg} alt="registration img" />
        </div>
        <div className="Registration-blockRight">
          <form onSubmit={handleSubmit(onSubmit)} className="Registration-form">
            <div className="Registration-title">
              <span className="Registration-titleCart">CART</span>&ORDER
                        </div>
            <div className="Registration-subTitle">
              <span className="Registration-subTitleCart">Business</span>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="New Password"
                  type="password"
                  error={errors.password}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('newPassword')}
                  name="newPassword"
                />
              </div>
            </div>
            <div className="Registration-box">
              <div className="Registration-boxCenter">
                <Input
                  label="Confirm password"
                  type="password"
                  error={errors.password}
                  refInput={register({
                    required: true,
                  })}
                  value={watch('confirmPassword')}
                  name="confirmPassword"
                />
              </div>
            </div>
            {error && (
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}
            <div className="Registration-blockButton">
              <Button
                disabled={submitting}
                classes='success submit-button'
                type="submit"
                onClick={() => {
                }}
                disabled={false}
              >
                {submitting ? (
                  <CircularProgress color='default' size='20px' />
                ) : 'Reset Password'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect()(ResetPassword)
